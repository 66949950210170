<template>
  <div class="materialsApply">
    <div class="content">
      <!-- 顶部 -->
      <Header :titleName="'捐赠项目'" :return="true"  :borderShow="true" />
      <!-- 列表为空 -->
      <div class="apply_list_empty" v-if="!data.detail">
        <img src="@/assets/images/materialsApply/apply_list_empty.png" alt="">
        <div class="first_div">未找到</div>
        <div class="last_div">此捐赠项目不存在或已删除~</div>
      </div>
      <div v-else>
        <div class="card" style="padding-top: .3rem;">


          <div class="title">{{ data.detail.title }}</div>
          <div class="time flex">
            <div class="mr50">{{ data.detail.addTime }}</div>
            <div><img src="@/assets/images/donate/eye.png" alt="">{{data.detail.click}}</div>
          </div>
          <div class="details_text" v-html="data.detail.content"></div>

        </div>


        
      </div>






    </div>

  </div>
</template>

<script setup>

import { reactive, onMounted } from "vue";
import Api from "../../utils/api";
import { useRouter, useRoute } from 'vue-router'
import Header from "../../components/Header.vue";
const route = useRoute()
const router = useRouter()

let data = reactive({

  id: 0,
  detail: null,
});

onMounted(() => {

  data.id = route.query.id
  Api.articleShow(data.id ).then(res => {
    if (res.status == 200) {
      data.detail = res.data
      data.detail.content = filterUnsafeHtml(res.data.content)

      data.detail.create_time = getYMDHMS(res.data.create_time)


      console.log(res.data)
    }
  })
});
const getYMDHMS = (timestamp) => {
  let time = new Date(timestamp*1000)
  let year = time.getFullYear()
  let month = time.getMonth() + 1
  let date = time.getDate()
  let hours = time.getHours()
  let minute = time.getMinutes()
  let second = time.getSeconds()

  // if (month < 10) { month = '0' + month }
  // if (date < 10) { date = '0' + date }
  if (hours < 10) { hours = '0' + hours }
  if (minute < 10) { minute = '0' + minute }
  if (second < 10) { second = '0' + second }
  return year + '年' + month + '月' + date + '日 ' + hours + ':' + minute + ':' + second
}

const filterUnsafeHtml = (str) => {
  var arrEntities = {
    'lt': '<',
    'gt': '>',
    'nbsp': ' ',
    'amp': '&',
    'quot': '"'
  };
  return str.replace(/&(lt|gt|nbsp|amp|quot);/ig,
    function (all, t) {
      return arrEntities[t];
    });
}
</script>

<style lang='less' scoped>
.materialsApply {
  display: flex;
  flex-flow: column;
  height: 100%;
  .details_text{
    /deep/img{
      max-width: 100% !important;
      display: block;
    }
	.details_text p{
		font-size: 16px;
	}
  }
  .content {
    flex: 1;
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden;
    background-color: #fff;

    .apply_list_empty {
      text-align: center;
      padding-top: 15vh;

      img {
        width: 6.2933rem;
        height: 4.52rem;
      }

      .first_div {
        font-size: .4667rem;
        font-weight: 500;
        margin-top: .6667rem;
      }

      .last_div {
        font-size: .36rem;
        color: #ACACAC;
        margin-top: .2rem;

      }
    }

    .card {
      padding: .4rem .5067rem;
    }

    .footer_btn {
      height: 2.3333rem;
      width: 100%;
      position: fixed;
      left: 0;
      bottom: 0;
      background-color: #fff;
      box-shadow: 0px -0.1333rem .08rem 1px rgba(0, 0, 0, 0.03);

      >div {
        width: 9.3867rem;
        height: 1.1333rem;
        background: #E61D18;
        border-radius: .1067rem;
        margin: .2667rem auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .3867rem;
        color: #fff;
      }
    }

    .details_text {
      font-size: .4667rem;
      line-height: .7rem;
    }

    .time {
      font-size: .3867rem;
      color: #ACACAC;
      margin-bottom: .6667rem;

      .mr50 {
        margin-right: .6667rem;
      }

      img {
        width: .4133rem;
        margin-right: .2133rem;
        vertical-align: text-top;
      }
    }

    .title {
      font-size: .56rem;
      font-weight: 600;
      margin-bottom: .4667rem;
    }

    .top {
      height: 1.1333rem;
      width: 100%;

      font-size: .44rem;
      text-align: center;
      padding-top: .3333rem;
      box-sizing: border-box;
      position: relative;

      .return {
        width: 1.2rem;
        height: 1.1333rem;
        position: absolute;
        left: 0;
        top: 0;
        padding-left: .4rem;
        display: flex;
        align-items: center;

        img {
          width: .2667rem;
        }
      }
    }





  }
}
</style>